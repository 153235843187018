<template>
  <b-modal
    id="invoice-history-modal"
    size="xl"
    ok-only
    ok-title="Close"
    body-class="table-responsive"
    no-close-on-backdrop
    @hide="$emit('close')"
    @close="$bvModal.hide('invoice-history-modal')"
    @hidden="resetForm"
    @shown="() => { setComponentReady(); form.reset = false }"
  >
    <template
      v-if="userInfo"
      #modal-title
    >
      <feather-icon
        icon="UserIcon"
        :style="'margin-bottom: 4px; margin-right: 2px;'"
      />
      <span>{{ userInfo.name }} - {{ $t('payments.invoice-history') }}</span>
    </template>
    <b-overlay :show="isProcessing">
      <b-row class="justify-content-between align-items-center">
        <b-col md="10">
          <div class="row ml-2">
            <b-form-group>
              <label class="mr-1">{{ $t('Date Range') }}</label>
              <date-range-filter
                @change="dateChange"
              />
            </b-form-group>

            <b-form-group class="mx-2">
              <label class="mr-1">{{ $t('Start Date') }}</label>
              <b-form-datepicker
                v-model="form.start_date"
                :date-format-options="COMMON.DATE_PICKER_FORMAT"
                :max="form.end_date"
                :disabled="!usingCustomDateRange"
              />
            </b-form-group>
            <b-form-group>
              <label class="mr-1">{{ $t('End Date') }}</label>
              <b-form-datepicker
                v-model="form.end_date"
                :min="form.start_date"
                :max="TODAY()"
                :disabled="!usingCustomDateRange"
                :date-format-options="COMMON.DATE_PICKER_FORMAT"
              />
            </b-form-group>
          </div>
        </b-col>

        <b-col md="2">
          <b-dropdown
            v-b-tooltip.hover="total ? 'Export All Pages' : 'Nothing to Export'"
            class="float-right"
            variant="success"
            split-variant="wz-teal"
            :style="'height: 48px;'"
            :disabled="!total || isExporting"
            split
            @click="exportPdf('all')"
          >
            <template #button-content>
              <b-spinner
                v-if="isExporting"
                class="mr-1"
                small
              />
              <feather-icon icon="PrinterIcon" />
              {{ $t('actions.export') }}
            </template>
            <b-dropdown-item @click="exportPdf('current')">
              {{ $t('Current Page') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <invoice-history-table
        id="invoice-history-table"
        :invoices="invoices"
        :per-page="perPage"
        :user-info="userInfo"
        :total="total"
        @pay="user => { selectedItem = user }"
        @cancel-payment="user => { selectedItem = user }"
        @paginate="(page) => { getUserInvoiceHistory(page) }"
      />
    </b-overlay>

    <b-modal
      v-if="selectedItem && userInfo"
      id="confirm-payment"
      :title="`Confirm Payment for ${userInfo.name}`"
      :ok-title="$t('Proceed')"
      :cancel-title="$t('Cancel')"
      no-close-on-backdrop
      :ok-disabled="selectedItem.isPaymentProgress"
      :cancel-disabled="selectedItem.isPaymentProgress"
      @ok="finalizePayment"
      @hidden="transactionNarration = ''"
    >
      <b-overlay :show="selectedItem.isPaymentProgress">
        <b-form-group>
          <template #label>
            {{ $t('payments.invoice-amount') }}
          </template>
          <div class="d-flex">
            <b-form-input
              class="col-4"
              :value="formatAmount(selectedItem.paidAmount)"
              :disabled="true"
            />
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mx-1 mb-2 text-info"
            />
            <span class="small text-warning">{{ $t('payments.amount-deduction-info') }}</span>
          </div>
        </b-form-group>
        <validation-observer ref="paymentForm">
          <validation-provider
            #default="{ errors }"
            name="transaction narration"
            rules="required"
          >
            <b-form-group
              class="mt-2"
              :label="$t('payments.transaction-narration')"
            >
              <b-form-input
                v-model="transactionNarration"
                placeholder="Eg. Salary"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <invoice-uploader
            @document-uploaded="url => document = url"
            @document-removed="() => document = null"
          />
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- Payment Cancel Modal -->
    <confirm-modal
      v-if="selectedItem && userInfo"
      modal="cancel-payment-modal"
      title="Cancel Payment"
      :alert="`Are you sure you want to cancel payment for ${userInfo.name}?`"
      alert-variant="danger"
      ok-title="Cancel Payment"
      ok-variant="danger"
      :ok-disabled="isPaymentCancelling"
      :cancel-disabled="isPaymentCancelling"
      @confirm="cancelPayment"
      @hidden="paymentCancelReason = ''"
    >
      <b-overlay :show="isPaymentCancelling">
        <b-form-group label="Narration">
          <b-form-input
            v-model="paymentCancelReason"
            placeholder="Payment Cancellation Narration"
            autofocus
            @keypress.enter="cancelPayment"
          />
        </b-form-group>
      </b-overlay>
    </confirm-modal>

    <invoice-history-table
      v-show="false"
      id="full-invoice-history-table"
      :invoices="fullInvoices"
      :user-info="userInfo"
      :per-page="total"
      :total="total"
    />
  </b-modal>
</template>
<script>
import {
  BRow, BCol, BModal, BOverlay, BFormGroup, BFormInput, BFormDatepicker, BDropdown, BDropdownItem, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import useApollo from '@/plugins/graphql/useApollo'
import InvoiceUploader from '@/views/common/components/InvoiceUploader.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { setComponentReady } from '@/views/tour/tour'
import DateRangeFilter from '@/views/common/components/DateRangeFilter.vue'
import moment from 'moment'
import ConfirmModal from '@/views/Wallet/components/reusables/ConfirmModal.vue'
import InvoiceHistoryTable from './InvoiceHistoryTable.vue'
import exportInvoice from './exportInvoice'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    InvoiceUploader,
    DateRangeFilter,
    InvoiceHistoryTable,
    ConfirmModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
    dateRange: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      setComponentReady,
      transactionNarration: '',
      invoices: [],
      fullInvoices: [],
      isProcessing: true,
      isPaymentProgress: false,
      isPaymentCancelling: false,
      selectedItem: null,
      currency: this.$store.state.project.company.currency,
      document: null,
      usingCustomDateRange: false,
      form: {
        start_date: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').format('YYYY-MM-DD 00:00:00'),
        end_date: this.TODAY_COMPANY_TIME().start,
      },
      total: 0,
      perPage: 10,
      isExporting: false,
      paymentCancelReason: '',
    }
  },
  computed: {
    ...mapGetters('project', ['USER_ROLE']),
  },
  watch: {
    userInfo(val) {
      if (val) this.getUserInvoiceHistory()
    },
    form: {
      handler(val) {
        if (!val.reset) this.getUserInvoiceHistory()
      },
      deep: true,
    },
  },
  methods: {
    dateChange(range) {
      if (!range.startTime && !range.endTime) {
        this.usingCustomDateRange = true
        return null
      }
      this.form.start_date = range.startTime
      this.form.end_date = range.endTime
      this.usingCustomDateRange = false

      this.getUserInvoiceHistory()
      return null
    },
    getUserInvoiceHistory(page = null, callback = null) {
      if (!callback) this.isProcessing = true
      useApollo.payroll.getUserInvoiceHistory({
        userUUID: this.userInfo.uuid,
        first: callback ? -1 : this.perPage,
        page: page ?? 1,
        startTime: this.FORMAT_DATE(this.form.start_date, 'YYYY-MM-DD 00:00:00'),
        endTime: this.ADD_DAYS(this.form.end_date, 2, 'YYYY-MM-DD 00:00:00').start ?? this.ADD_DAYS(this.TODAY(), 1, 'YYYY-MM-DD 00:00:00').start,
        projectUUID: this.$store.state.project.selectedProject,
      }).then(response => {
        const { invoices } = response.data?.user
        if (callback) this.fullInvoices = invoices.data
        else this.invoices = invoices.data.map(item => ({ ...item, isPaymentProgress: false }))
        this.total = invoices.total
      }).finally(() => {
        if (callback) {
          const element = document.getElementById('full-invoice-history-table')
          this.$nextTick(() => {
            callback(element)
          })
        }
        this.isProcessing = false
      })
    },
    finalizePayment($bvModal) {
      $bvModal.preventDefault()
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          this.selectedItem.isPaymentProgress = true
          const params = {
            userUid: this.userInfo.uuid,
            projectUid: this.$store.state.project.selectedProject,
            invoiceId: Number(this.selectedItem.invoiceId),
            reason: this.transactionNarration,
            documentData: this.document,
          }

          useApollo.payroll.finalizePayment(params).then(() => {
            this.showSuccess('Payment processed successfully')
            this.getUserInvoiceHistory()
          }).catch(error => {
            this.showErrorMessage(error)
            this.selectedItem.isPaymentProgress = false
          }).finally(() => {
            this.selectedItem.isPaymentProgress = false
            this.$bvModal.hide('confirm-payment')
          })
        }
      })
    },
    resetForm() {
      this.form = {
        reset: true,
        start_date: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').format('YYYY-MM-DD 00:00:00'),
        end_date: this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start,
      }
    },
    async exportPdf(page = 'all') {
      this.isExporting = true
      const element = document.getElementById('invoice-history-table')
      const width = element.offsetWidth * 0.82

      if (page === 'all' && this.total > this.perPage) {
        this.getUserInvoiceHistory(null, el => {
          exportInvoice(el, width).then(() => {
            this.isExporting = false
          })
        })
      } else {
        exportInvoice(element, width).then(() => {
          this.isExporting = false
        })
      }
    },
    cancelPayment() {
      this.isPaymentCancelling = true
      const params = {
        userUid: this.userInfo.uuid,
        projectUid: this.$store.state.project.selectedProject,
        invoiceId: Number(this.selectedItem.invoiceId),
        reason: this.paymentCancelReason,
      }

      useApollo.company.cancelUserPayment(params).then(response => {
        this.showSuccess(response.data.cancelUserPayment.message)
        this.getUserInvoiceHistory()
      }).catch(error => {
        this.isPaymentCancelling = false
        this.showErrorMessage(error)
      }).finally(() => {
        this.isPaymentCancelling = false
        this.$bvModal.hide('cancel-payment-modal')
        this.$emit('refetch')
      })
    },
  },
}
</script>

<style>
#invoice-history-modal___BV_modal_body_ {
  min-height: 520px;
}
</style>
