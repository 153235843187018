import store from '@/store'
import html2pdf from 'html2pdf.js'
import { getUserData } from '@/auth/utils'

const buildContent = (element, width) => new Promise(resolve => {
  const invoiceHistory = element.cloneNode(true)
  invoiceHistory.style.display = 'block'
  invoiceHistory.firstChild.classList.add('light-table')
  const content = document.createElement('div')
  content.style.width = `${width}px`

  content.innerHTML = `
    <div class="d-flex justify-content-between p-2" style="color:rgb(110, 107, 123);">
      <div>
        <h2 style="color:rgb(110, 107, 123);">${store.state.project.company.name}</h2>
        <span style="color:rgb(110, 107, 123);">${store.state.project.company.address ?? ''}</span>
      </div>

      <div class="text-right">
        <p>Date: ${new Date().toLocaleDateString()}</p>
        <p>Name: ${getUserData().name}</p>
      </div>
    </div>

    <h2 class="text-center" style="color:rgb(110, 107, 123);">Invoice List</h2>
  `

  // Change 'Pay' button to '-'
  const buttons = invoiceHistory.querySelectorAll('button')
  const dueText = document.createTextNode('-')

  buttons.forEach(button => {
    button.parentNode.replaceChild(dueText.cloneNode(true), button)
  })

  // Remove paginations
  const pagination = invoiceHistory.querySelector('.pagination')
  if (pagination) pagination.style.display = 'none'

  content.appendChild(invoiceHistory)

  resolve(content)
})

const opt = width => ({
  margin: 0,
  filename: 'invoice-history.pdf',
  image: { type: 'jpeg', quality: 0.98 },
  html2canvas: {
    scale: 2,
    width,
    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  },
  jsPDF: { format: 'a4', orientation: 'portrait' },
})

export default async (element, width) => {
  try {
    const options = opt(width)
    const content = await buildContent(element, width)

    return new Promise(resolve => {
      resolve(html2pdf().set(options).from(content).save())
    })
  } catch (error) {
    console.error('Error exporting invoice:', error)
    return null
  }
}
