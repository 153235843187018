<template>
  <div>
    <b-form-group label="Upload Document">
      <custom-image-upload
        height="220"
        :pdf-allowed="true"
        title="Document"
        @imageUploaded="(img,url) => $emit('document-uploaded', url)"
        @imageRemoved="() => $emit('document-removed')"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import CustomImageUpload from './CustomImageUpload.vue'

export default {
  components: {
    BFormGroup,
    CustomImageUpload,
  },
}
</script>
